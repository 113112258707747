<template>
  <div :class="`loading-${size}`" class="loading loading--full-height"></div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'base'
    }
  }
}
</script>
