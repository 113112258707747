import Axios from 'axios'
import AxiosIsCancel from 'axios/lib/cancel/isCancel'
import store from '../store/index'
const baseAPIURL = process.env.VUE_APP_BASE_URL
const axios = Axios.create({
  baseURL: baseAPIURL
})
axios.interceptors.request.use(
  (config) => {
    if (config.url === '/users/list-accounts' || config.url === '/users/select-account') {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    } else {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('token_access')
    }
    const source = Axios.CancelToken.source()

    // Set cancel token on this request
    config.cancelToken = source.token
    store.commit('ADD_CANCEL_TOKEN', source)
    return config
  },
  async (req) => {
    return req
  },
  (error) => {
    return Promise.reject(error).finally(console.clear)
  }
)

axios.interceptors.response.use(
  async (res) => {
    if (res.data.error) {
      if (!AxiosIsCancel(res)) {
        console.error(res.data.error.message || res.data.error)
      }
    }
    return res
  },
  async (error) => {
    return Promise.reject(error)
  }
)

export default axios
