import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { verkIcon, einIcon, gearIcon, boxIcon } from '@/libs/custom-icons.js'
library.add(faTimes)
// Custom Icons
library.add(verkIcon)
library.add(einIcon)
library.add(boxIcon)
library.add(gearIcon)
// Registry Library
Vue.component('v-icon', FontAwesomeIcon)
