<template>
  <div id="app">
    <div v-if="loading">
    </div>
    <loading v-if="loading" />
    <router-view v-if="!loading" :key="$route.fullPath"/>
  </div>
</template>
<script>
import axios from 'axios'
import { msalMixin } from 'vue-msal'
import loading from '@/components/common/Loading'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  mixins: [msalMixin],
  components: {
    loading
  },
  data () {
    return {
      loading: true,
      groups: [
        {
          id: '25fae66c-d81b-4fe8-9567-2635d85425e4',
          name: 'seller'
        }
      ],
      userGroup: null
    }
  },
  computed: {
    ...mapGetters(['getLoadingRoute', 'getMsAccessToken'])
  },
  watch: {
    'msal.accessToken': {
      async handler (value) {
        if (value) {
          console.info('Token', value)
          this.SET_ACCESSTOKEN(value)
          this.SET_MS_ACCESSTOKEN(value)
          try {
            await this.refreshToken()
          } catch (e) {
            const isUnauthorizedError = e?.response?.status === 401 || e?.response?.status === 403
            if (isUnauthorizedError) {
              this.$msal.signOut()
            }
          } finally {
            this.loading = false
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations(['SET_USER_ROLE', 'SET_ACCESSTOKEN', 'SET_MS_ACCESSTOKEN']),
    ...mapActions(['refreshToken']),
    async refreshAccessToken () {
      const memberOf = await axios.get('https://graph.microsoft.com/v1.0/me/memberOf', {
        headers: {
          Authorization: `Bearer ${this.getMsAccessToken}`
        }
      })
      console.info(memberOf)
    },
    isRootPath () {
      return window.location.pathname === '/'
    }
  },
  async created () {
    const url = new URL(window.location.href)
    const hash = new URLSearchParams(url.hash.substring(1))

    if (!this.$msal.isAuthenticated() && this.isRootPath() && hash.get('access_token')) {
      window.location.replace(window.location.origin)
    }

    setInterval(() => {
      this.refreshToken()
      setTimeout(() => { location.reload() }, 3000)
    }, 30 * 60 * 1000)
  }
}
</script>
<style lang="scss">
@import "assets/scss/main.scss";
</style>
