const verkIcon = {
  prefix: 'fac',
  iconName: 'verk',
  icon: [
    19,
    20,
    [],
    null,
    'M3.07446 2.05128H14.3565C14.9005 2.05128 15.4222 2.2674 15.8071 2.65201C16.1917 3.03686 16.4078 3.55862 16.4078 4.10256V15.8974C16.4078 16.4414 16.1917 16.9632 15.8071 17.348C15.4222 17.7326 14.9004 17.9487 14.3565 17.9487H3.07446C2.70793 17.9487 2.36934 18.1442 2.18619 18.4615C2.00304 18.7788 2.00304 19.1699 2.18619 19.4872C2.36934 19.8045 2.70794 20 3.07446 20H14.3565C15.4446 20 16.4881 19.5678 17.2574 18.7983C18.0268 18.0291 18.4591 16.9856 18.4591 15.8974V4.10256C18.4591 3.01445 18.0268 1.97093 17.2574 1.2017C16.4881 0.432234 15.4446 0 14.3565 0H3.07446C2.70793 0 2.36934 0.195511 2.18619 0.51282C2.00304 0.83013 2.00304 1.22116 2.18619 1.53846C2.36934 1.85577 2.70794 2.05128 3.07446 2.05128ZM1.72131 5.70659C1.33221 5.31749 0.701369 5.31749 0.312275 5.70659C-0.0768182 6.09568 -0.0768177 6.72653 0.312276 7.11562L3.18621 9.98955L0.29182 12.8839C-0.0972735 13.273 -0.0972733 13.9039 0.29182 14.293C0.680914 14.6821 1.31176 14.6821 1.70085 14.293L5.26488 10.729C5.36687 10.627 5.44212 10.5084 5.49065 10.3819C5.65424 10.0157 5.5858 9.57108 5.28533 9.27061L1.72131 5.70659ZM4.99834 5.70656C5.38743 5.31747 6.01828 5.31747 6.40737 5.70656L9.97139 9.27059C10.2719 9.57106 10.3403 10.0157 10.1767 10.3818C10.1282 10.5083 10.0529 10.6269 9.95094 10.7289L6.38692 14.293C5.99782 14.682 5.36698 14.682 4.97788 14.293C4.58879 13.9039 4.58879 13.273 4.97788 12.8839L7.87227 9.98953L4.99834 7.1156C4.60924 6.7265 4.60924 6.09566 4.99834 5.70656Z'
  ]
}
const einIcon = {
  prefix: 'fac',
  iconName: 'ein',
  icon: [
    19,
    20,
    [],
    null,
    'M14.9504 17.4421H3.98676C3.45816 17.4421 2.95111 17.2321 2.57716 16.8583C2.2034 16.4843 1.99338 15.9773 1.99338 15.4487V3.98676C1.99338 3.45816 2.2034 2.95111 2.57716 2.57716C2.95114 2.2034 3.45817 1.99338 3.98676 1.99338H14.9504C15.3065 1.99338 15.6356 1.80339 15.8135 1.49503C15.9915 1.18668 15.9915 0.806693 15.8135 0.498345C15.6356 0.189998 15.3065 0 14.9504 0H3.98676C2.92936 0 1.9153 0.420033 1.16778 1.16778C0.420034 1.9153 0 2.92936 0 3.98676V15.4487C0 16.5061 0.420034 17.5202 1.16778 18.2677C1.9153 19.0154 2.92936 19.4355 3.98676 19.4355H14.9504C15.3065 19.4355 15.6356 19.2455 15.8135 18.9371C15.9915 18.6288 15.9915 18.2488 15.8135 17.9404C15.6356 17.6321 15.3065 17.4421 14.9504 17.4421ZM11.699 5.54555C11.3208 5.16744 10.7078 5.16744 10.3297 5.54555C9.95159 5.92366 9.95159 6.5367 10.3297 6.91481L13.1225 9.7076L10.3099 12.5202C9.93175 12.8983 9.93175 13.5114 10.3099 13.8895C10.688 14.2676 11.301 14.2676 11.6791 13.8895L15.1425 10.4261C15.2417 10.3269 15.3148 10.2117 15.362 10.0887C15.5209 9.73292 15.4543 9.30092 15.1624 9.00897L11.699 5.54555ZM14.884 5.54549C15.2621 5.16738 15.8752 5.16738 16.2533 5.54549L19.7167 9.00891C20.0088 9.30106 20.0753 9.73345 19.916 10.0894C19.8688 10.212 19.7958 10.327 19.6969 10.4259L16.2334 13.8893C15.8553 14.2674 15.2423 14.2674 14.8642 13.8893C14.4861 13.5112 14.4861 12.8982 14.8642 12.52L17.6767 9.70748L14.884 6.91475C14.5059 6.53664 14.5059 5.9236 14.884 5.54549Z'
  ]
}
const gearIcon = {
  prefix: 'fac',
  iconName: 'gear',
  icon: [
    256,
    256,
    [],
    null,
    'M183.7,65.1q3.8,3.5,7.2,7.2l27.3,3.9a103.2,103.2,0,0,1,10.2,24.6l-16.6,22.1s.3,6.8,0,10.2l16.6,22.1a102.2,102.2,0,0,1-10.2,24.6l-27.3,3.9s-4.7,4.9-7.2,7.2l-3.9,27.3a103.2,103.2,0,0,1-24.6,10.2l-22.1-16.6a57.9,57.9,0,0,1-10.2,0l-22.1,16.6a102.2,102.2,0,0,1-24.6-10.2l-3.9-27.3q-3.7-3.5-7.2-7.2l-27.3-3.9a103.2,103.2,0,0,1-10.2-24.6l16.6-22.1s-.3-6.8,0-10.2L27.6,100.8A102.2,102.2,0,0,1,37.8,76.2l27.3-3.9q3.5-3.7,7.2-7.2l3.9-27.3a103.2,103.2,0,0,1,24.6-10.2l22.1,16.6a57.9,57.9,0,0,1,10.2,0l22.1-16.6a102.2,102.2,0,0,1,24.6,10.2Z'
  ]
}
const boxIcon = {
  prefix: 'fac',
  iconName: 'box',
  icon: [
    10,
    10,
    [],
    null,
    'M0 1C0 0.447715 0.447715 0 1 0H3C3.55228 0 4 0.447715 4 1V3C4 3.55228 3.55228 4 3 4H1C0.447715 4 0 3.55228 0 3V1ZM1 6C0.447715 6 0 6.44772 0 7V9C0 9.55229 0.447715 10 1 10H3C3.55228 10 4 9.55229 4 9V7C4 6.44772 3.55228 6 3 6H1ZM7 6C6.44772 6 6 6.44772 6 7V9C6 9.55229 6.44772 10 7 10H9C9.55229 10 10 9.55229 10 9V7C10 6.44772 9.55229 6 9 6H7ZM7 0C6.44772 0 6 0.447715 6 1V3C6 3.55228 6.44772 4 7 4H9C9.55229 4 10 3.55228 10 3V1C10 0.447715 9.55229 0 9 0H7Z'
  ]
}
export {
  verkIcon,
  einIcon,
  gearIcon,
  boxIcon
}
