import axios from '../plugins/axios'
export default {
  // Search
  async searchData ({ searchKeyw = null, limit = 50, page = 1, type = 'asc' }) {
    return await axios.get(
      `/search?term=${encodeURIComponent(searchKeyw)}&limit=${limit}&page=${page}&sortType=${type}`
    )
  },
  // Seller List
  async getSellerList ({
    limit = 50,
    page = 1,
    sortType = 'asc',
    searchKeyw = '',
    sortField = 'supplierName',
    filter = ''
  }) {
    let url = `/supplier/sales-list?limit=${limit}&page=${page}&sortType=${sortType}&search=${encodeURIComponent(searchKeyw)}&sortField=${sortField}`
    if (filter) {
      url += `&filter=${encodeURIComponent(filter)}`
    }
    return await axios.get(url)
  },
  // purchase List
  async getPurchaserList ({
    limit = 50,
    page = 1,
    sortType = 'asc',
    searchKeyw = '',
    sortField = 'supplierName',
    filter = ''
  }) {
    let url = `/supplier/purchase-list?limit=${limit}&page=${page}&sortType=${sortType}&search=${encodeURIComponent(searchKeyw)}&sortField=${sortField}`
    if (filter) {
      url += `&filter=${encodeURIComponent(filter)}`
    }
    return await axios.get(url)
  },
  // Model List
  async getModelList ({
    id,
    limit = 50,
    page = 1,
    sortType = 'asc',
    searchKeyw = '',
    filter = ''
  }) {
    let url = `/supplier/${id}/model-list?limit=${limit}&page=${page}&sortType=${sortType}&search=${encodeURIComponent(searchKeyw)}`
    if (filter) {
      url += `&filter=${filter}`
    }
    return await axios.get(url)
  },
  // Filter Model List
  async filterModel ({
    limit = 50,
    page = 1,
    sortType = 'asc',
    search = '',
    filter = ''
  }) {
    let url = `/model/list?limit=${limit}&page=${page}&sortType=${sortType}&search=${encodeURIComponent(search)}`
    if (filter) {
      url += `&filter=${filter}`
    }
    return await axios.get(url)
  },
  // Model List
  async getGoodsGroup ({
    limit = 50,
    page = 1,
    sortType = 'asc',
    supplierQuery = '',
    modelQuery = '',
    goodsGroupQuery = ''
  }) {
    let url = `/supplier/goodsgroup?limit=${limit}&page=${page}&sortType=${sortType}`
    if (supplierQuery) {
      url += `&supplierQuery=${supplierQuery}`
    }
    if (modelQuery) {
      url += `&modelQuery=${modelQuery}`
    }
    if (goodsGroupQuery) {
      url += `&goodsGroupQuery=${goodsGroupQuery}`
    }
    return await axios.get(url)
  },
  async getCalulations (supplierId, modelId) {
    return await axios.get(`/settings/calculation?supplierId=${supplierId}&modelId=${modelId}`)
  },
  // Model Detail
  async getModelDetail (supplierId, modelId) {
    return await axios.get(`supplier/${supplierId}/model/${modelId}`)
  },
  // Firmen get Settings
  async getSetting () {
    return await axios.get('/settings')
  },
  // Firmen LOGO hochladen
  async uploadFirmenLogo (formData, config) {
    return await axios.post('/settings/logo', formData, config)
  },
  // Firmen Update Settings
  async updateSettings (data) {
    return await axios.post('/settings', data)
  },
  async deleteSettings (data) {
    return await axios.post('/settings/delete', data)
  },
  // Firmen Steps
  async getSteps () {
    return await axios.get('/settings/step-values')
  },
  async searchSupplier (data) {
    return await axios.get(
      `/supplier/list?limit=${data.limit}&page=${data.page}&sortType=asc&search=${encodeURIComponent(data.keyw)}&filter=${data.filter}`
    )
  },
  async getCalcList (data) {
    if (data.keyw) {
      return await axios.get(
        `/settings?type=${data.type}&limit=10&page=${data.page}&search=${encodeURIComponent(data.keyw)}`
      )
    }
    return await axios.get(
      `/settings?type=${data.type}&limit=10&page=${data.page}`
    )
  },
  async getFileList (data) {
    return await axios.get(
      `/coversheet/list?limit=10&page=${data.page}&supplierId=${data.supplier}&modelId=${data.model}`
    )
  },
  async getPricelist (data) {
    return await axios.get(`coversheet/filter?supplierId=${data.supplierId}&modelId=${data.modelId}&validFrom=${data.validFrom}&validTill=${data.validTill}&status=${data.status}&tz=${data.tz === 'ja'}`)
  },
  async getDelFileList (data) {
    return await axios.get(
      `/coversheet/list-deleted?limit=10&page=${data.page}&supplierId=${data.supplier}&modelId=${data.model}`
    )
  },
  async updateFile (old, newM) {
    return await axios.post(
      '/coversheet/update', { oldMeta: old, newMeta: newM }
    )
  },
  async checkOverlap (old, newM) {
    return await axios.post(
      '/coversheet/check-overlap', { oldMeta: old, newMeta: newM }
    )
  },
  async delFile (data) {
    return await axios.delete(
      '/coversheet/delete', { data: data }
    )
  },
  async getkalculations (data) {
    return await axios.get(`/settings/supplier/${data.supplierId}/model/${data.modelId}/calculation`)
  },
  async getPdfPrivew (data) {
    return await axios.get(`/catalogue/supplier/${data.supplierId}/model/${data.modelId}/view-latest?step=${data.step}`)
  },
  async downloadPdf (data) {
    return await axios.post(
      `/catalogue/supplier/${data.supplierId}/model/${data.modelId}/download-latest`,
      { step: data.step }
    )
  },
  // VME admin update
  async updateForm (formData, config) {
    return await axios.post('/settings/coversheet', formData, config)
  },
  async getModelCatalog (par, type) {
    return await axios.get(
      `/catalogue/supplier/${par.supplierId}/model/${par.modelId}?as=${type}`
    )
  },
  async searchUser (search) {
    return await axios.get(`users?search=${encodeURIComponent(search)}`)
  },
  async getUserList (token = null) {
    if (token) {
      return await axios.get(`/users?nextSkipToken=${token}`)
    }
    return await axios.get('/users')
  },
  async updateList (par) {
    return await axios.post('/users/roles', par)
  },
  async refreshToen (companyId) {
    return this.selectAccount(companyId)
  },
  async getContactsBySupplier (id) {
    return await axios.get(`/supplier/${id}/contact-list`)
  },
  async downloadCatalog (par) {
    return await axios.post(
      `/catalogue/supplier/${par.supplierId}/model/${par.modelId}/download`,
      par.body,
      {
        config: { responseType: 'blob' }
      }
    )
  },
  async getCompanies () {
    return await axios.get('/users/list-accounts')
  },
  async selectAccount (id) {
    return await axios.post('/users/select-account', { companyId: id })
  },
  async viewDocument (par) {
    let url = `/catalogue/supplier/${par.supplierId}/model/${par.modelId}/view?validFrom=${par.validFrom}&validTill=${par.validTill}&status=${par.status}&step=${par.stepValue}&tz=${par.tz}`
    if (par.zusatznr) url += `&zusatznr=${par.zusatznr}`
    return await axios.get(url)
  }
}
